let jt = [];

jt['share'] = '分享';
jt['punch_card'] = '打卡';
jt['task'] = '任务';
jt['index'] = '首页';
jt['content'] = '内容';
jt['my'] = '我的';
jt['in_regard_to'] = '关于';
jt['study'] = '学习';
jt['team_training'] = '团队培训';
jt['team_members'] = '团队成员';


jt['recommend'] = '推荐';
jt['newest'] = '最新';
jt['all'] = '全部';

jt['location'] = '定位见面位置';

jt['address_text'] = "请填写地址";

jt['confirm'] = '确 定';
jt['delete_confirm'] = '确定删除';
jt['client_name'] = "请填写客户姓名";

jt['a_common_task'] = '共同任务';
jt['custom_task'] = '自订任务';
jt['update_or_delete_task'] = '长按项目可编辑或刪除';

jt['add_a_punch_in_item'] = '添加打卡项目';
jt['account'] = '账户';

jt['save'] = '保存';
jt['head_portrait'] = '头像';
jt['name'] = '姓名';
jt['phone'] = '电话';


jt['input_name'] = '请填写姓名';
jt['input_phone'] = '请填写电话';
jt['input_number'] = '请填写编号';
jt['input_email'] = '请填写邮箱';
jt['input_individual_label'] = '请填写标签,用逗号分隔';
jt['input_individual_resume'] = '请填写个人简介';


jt['number'] = '编号';
jt['email'] = '邮箱';
jt['gender'] = '性别';

jt['individual_label'] = '个人标签';
jt['individual_resume'] = '个人简介';


jt['reset_password'] = '重设密码';
jt['former_password'] = '旧密码';
jt['new_password'] = '新密码';

jt['please_enter_former_password'] = '请输入旧密码';
jt['please_enter_new_password'] = '请输入新密码';

jt['update_password'] = '修改密码';


jt['the_scoring_rules'] = '计分规则';
jt['no_content'] = '暂未有相关内容';


jt['my_favorite'] = '我的收藏';
jt['bookmark'] = '书签';

jt['activation_record'] = '活动记录';

jt['set_font_size'] = '设定字体大小';
jt['select_font_size'] = '请点击选择适合你的字体大小';
jt['select_top_font_size'] = '选择上面字体大小';

jt['switch_to_simple_and_traditional'] = '切换简繁体';
jt['chinese_traditional'] = '繁体中文';
jt['simplified_chinese'] = '简体中文';

jt['cut_use_position'] = '切换使用位置';
jt['for_use_in_hong_kong_and_macau'] = '在香港及澳门使用';
jt['in_regard_to_allstar'] = '关于ALLSTAR';

jt['count_off_today'] = '今日报数';
jt['target_number'] = '每月目标';
jt['online_conversation'] = '线上对话';
jt['call_phones'] = '打出电话';
jt['value'] = '请填写内容';
jt['interview_contents'] = '约谈';
jt['demo_concept'] = '演示概念';
jt['product'] = '产品';
jt['open_cases'] = '开Case';
jt['try_clinchs'] = '尝试成交';
jt['get_referrals'] = '获得转介';
jt['sign_bills'] = '签单';
jt['commissions'] = '佣金';
jt['set_target_number'] = '设置你的月度目标';
jt['input_target_number'] = "输入月度目标";


jt['title'] = "标题";
jt['title_placeholder'] = "请填写标题";
jt['text_placeholder'] = "请填写个人简介";

jt['subheading'] = "副标题";
jt['text_subheading'] = "请填写副标题";
jt['type'] = "类型";
jt['checks'] = "简单勾选";
jt['image_note'] = "图片+笔记";
jt['list_and_location'] = "名单及位置记";
jt['set_number']='设置数量';
jt['text_number']='请填写数量';

jt['hot_search']='热门搜索';
jt['search_result']='抱歉，暂时未有相关的结果';

export default function zh_jt(go) {
    return jt[go];
}