let ft = [];

ft['share'] = '分享';
ft['punch_card'] = '打卡';
ft['task'] = '任務';
ft['location'] = '定位見面位置';
ft['index'] = '首頁';
ft['content'] = '内容';
ft['my'] = '我的';
ft['in_regard_to'] = '關於';
ft['study'] = '學習';
ft['team_training'] = '團隊培訓';
ft['team_members'] = '團隊成員';


ft['recommend'] = '推薦';
ft['newest'] = '最新';
ft['all'] = '全部';
ft['confirm'] = '確 定';
ft['delete_confirm'] = '確定刪除';

ft['a_common_task'] = '共同任務';
ft['custom_task'] = '自訂任務';
ft['update_or_delete_task'] = '長按項目可編輯或刪除';

ft['add_a_punch_in_item'] = '添加打卡項目';
ft['account'] = '賬戶';

ft['save'] = '保存';
ft['head_portrait'] = '頭像';
ft['name'] = '姓名';
ft['phone'] = '電話';

ft['input_name'] = '請填寫姓名';
ft['input_phone'] = '請填寫電話';
ft['input_number'] = '請填寫編號';
ft['input_email'] = '請填寫郵箱';
ft['input_individual_label'] = '請填寫標簽,用逗號分隔';
ft['input_individual_resume'] = '請填寫個人簡介';

ft['number'] = '編號';
ft['email'] = '郵箱';
ft['gender'] = '性別';

ft['individual_label'] = '個人標簽';
ft['individual_resume'] = '個人簡介';


ft['reset_password'] = '重設密碼';
ft['former_password'] = '舊密碼';
ft['new_password'] = '新密碼';

ft['please_enter_former_password'] = '請輸入舊密碼';
ft['please_enter_new_password'] = '請輸入新密碼';

ft['update_password'] = '修改密碼';


ft['the_scoring_rules'] = '計分規則';
ft['no_content'] = '暫未有相關内容';


ft['my_favorite'] = '我的收藏';
ft['bookmark'] = '書簽';

ft['activation_record'] = '活動記錄';

ft['set_font_size'] = '設定字體大小';
ft['select_font_size'] = '請點擊選擇適合你的字體大小。';
ft['select_top_font_size'] = '選擇上面的字體大小';

ft['switch_to_simple_and_traditional'] = '切換簡繁體';
ft['chinese_traditional'] = '繁體中文';
ft['simplified_chinese'] = '簡體中文';

ft['cut_use_position'] = '切換使用位置';
ft['for_use_in_hong_kong_and_macau'] = '在香港及澳門使用';
ft['in_regard_to_allstar'] = '關於ALLSTAR';
ft['address_text'] = "請填寫地址";
ft['client_name'] = "請填寫客戶姓名";


ft['count_off_today'] = '今日報數';
ft['target_number'] = '每月目標';
ft['online_conversation'] = '線上對話';
ft['call_phones'] = '打出電話';
ft['value'] = '請填寫内容';
ft['interview_contents'] = '約談';
ft['demo_concept'] = '演示概念';
ft['product'] = '產品';
ft['try_clinchs'] = '嘗試成交';
ft['open_cases'] = '開Case';
ft['get_referrals'] = '獲得轉介';
ft['sign_bills'] = '簽單';
ft['commissions'] = '佣金';
ft['set_target_number'] = '設置你的月度目標';
ft['input_target_number'] = "輸入月度目標";

ft['title'] = "標題";
ft['title_placeholder'] = "請填寫標題";
ft['text_placeholder'] = "請填寫個人簡介";

ft['subheading'] = "副標題";
ft['text_subheading'] = "請填寫副標題";
ft['type'] = "類型";
ft['checks'] = "簡單勾選";
ft['image_note'] = "圖片+筆記";
ft['list_and_location'] = "名單及位置記";
ft['set_number']='設置數量';
ft['text_number']='請填寫數量';
ft['hot_search']='熱門搜索';
ft['search_result']='抱歉，暫時未有相關結果';
export default function zh_ft(go) {
    return ft[go];
}