import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import zh_tran from './assets/langs/lang';
import zh_jt from './assets/langs/jt';
import zh_ft from './assets/langs/ft';
import installElementPlus from './plugins/element'
import axios from './api/axios';
import md5 from 'js-md5';
import vConsole from '@/assets/vconsole.js'

const app = createApp(App);
installElementPlus(app);

// //设置axios请求头加入token
// axios.interceptors.request.use(config => { 
// if (config.push === '/login') {
//     console.log(123); 
//     } else { 
//         console.log(456); 
//         console.log(localStorage.getItem('token'));
//     if (localStorage.getItem('token')) { 
//     //在请求头加入token，名字要和后端接收请求头的token名字一样 
//     config.headers.token=localStorage.getItem('token');  
//     } 
//     } 
//     return config; 
// }, 
// error => { 
//     return Promise.reject(error);
// });

 // 添加请求拦截器
  axios.interceptors.request.use(function (config) {
   // 在发送请求之前做些什么
  // 判断是否存在token,如果存在将每个页面header添加token
      if (store.state.login_verify) {
        config.headers.common['Authorization'] = "Bearer " + store.state.login_verify
      }
      
      config.withCredentials = true
      return config
    }, function (error) {
    return Promise.reject(error)
    })

    
//响应回来token是否过期
axios.interceptors.response.use(function(response){
  return response
    }, 
   error => {
     if(error.status == '401'){
      localStorage.removeItem('token');
      localStorage.removeItem('user_avatar');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_name');
      localStorage.removeItem('month_goal');
      // 儅你退出賬號時 默認語言為繁體
      localStorage.setItem('lang_id',1);
      store.state.login_out = -1;
      router.push({path:'/login'});
      // location.reload();
      location.href=document.location.protocol+'//'+window.location.host+"/login";
    }else if(error.status == '419'){
      store.state.login_out = -2;
    }
    return Promise.reject(error); 
    })


    // 全局ctx(this) 上挂载 $axios
app.config.globalProperties.$axios = axios
app.config.globalProperties.$md5 = md5

app.config.globalProperties.$zh_tran = zh_tran
app.config.globalProperties.$zh_jt = zh_jt
app.config.globalProperties.$zh_ft = zh_ft
app.use(router).use(store).use(vConsole).mount('#app');
