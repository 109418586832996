import { createRouter, createWebHistory  } from 'vue-router';
import store from '../store'
// import routers from './routers';

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/header',
    name: 'header',
    component: () => import('../views/Header'),
  }
  ,
  {
    path: '/content',
    name: 'content',
    component: () => import('../views/content'),
  }
  ,
  {
    path: '/task',
    name: 'task',
    component: () => import('../views/task'),
  }
  ,
  {
    path: '/my',
    name: 'my',
    component: () => import('../views/my'),
  }
  ,
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login'),
  }
  ,
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register'),
  }
  ,
  {
    path: '/task/grade_table',
    name: 'grade_table',
    component: () => import('../views/task/grade_table'),
  }
  ,
  {
    path: '/my/account',
    name: 'account',
    component: () => import('../views/my/account'),
  }
  ,
  {
    path: '/my/reset_password',
    name: 'reset_password',
    component: () => import('../views/my/reset_password'),
  },
  {
    path: '/my/faq',
    name: 'faq',
    component: () => import('../views/my/faq'),
  }
  ,{
    path: '/my/point_rule',
    name: 'point_rule',
    component: () => import('../views/my/point_rule'),
  },{
    path: '/my/collect',
    name: 'collect',
    component: () => import('../views/my/collect'),
  },{
    path: '/my/activation_record',
    name: 'activation_record',
    component: () => import('../views/my/activation_record'),
  }
  ,{
    path: '/my/activation_record/:id',
    name: 'activation_record_detail',
    component: () => import('../views/my/activation_record_detail'),
  }
  ,{
    path: '/my/regard_allstar',
    name: 'regard_allstar',
    component: () => import('../views/my/regard_allstar'),
  }
  ,{
    path: '/task/add_punch_card_project',
    name: 'add_punch_card_project',
    component: () => import('../views/task/add_punch_card_project'),
  }
  ,{
    path: '/task/add_punch_card_project/:id',
    name: 'add_punch_card_project_detail',
    component: () => import('../views/task/add_punch_card_project'),
  }
  ,{
    path: '/task/add_activation_record/read/:id',
    name: 'add_activation_record',
    component: () => import('../views/task/add_activation_record'),
  }
  // 分享頁
  ,{
    path: '/share',
    name: 'share',
    component: () => import('../views/index/share'),
  },
  // 搜索頁
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/index/search'),
  },
  // 搜索結果頁
  {
    path: '/search_result/index',
    name: 'search_result',
    component: () => import('../views/search_result'),
  }
  // 訓練進度表
  ,{
    path: '/training_schedule',
    name: 'training_schedule',
    component: () => import('../views/training_schedule'),
  }
  // 訓練進度表詳情頁
  ,{
    path: '/training_schedule/:id',
    name: 'training_schedule_detail',
    component: () => import('../views/training_schedule/detail'),
  }
  // 課程頁
  ,{
    path: '/lesson',
    name: 'lesson',
    component: () => import('../views/lesson'),
  }
  // 主題頁
  ,{
    path: '/topic',
    name: 'topic',
    component: () => import('../views/topic'),
  }
  // 主題詳情頁
  ,{
    path: '/topic/:id',
    name: 'topic_detail',
    component: () => import('../views/topic/detail'),
  }
  // 評分頁面
  ,{
    path: '/lesson_done/:id',
    name: 'lesson_done',
    component: () => import('../views/lesson/lesson_done'),
  }
  // 每周推薦頁
  ,{
    path: '/s_topic',
    name: 's_topic',
    component: () => import('../views/s_topic'),
  }
  // 每周推薦詳情頁
  ,{
    path: '/s_topic/:id',
    name: 's_topic_detail',
    component: () => import('../views/s_topic/detail'),
  }
  //vip 產品中心
  ,{
    path: '/vippay/:id',
    name: 'vippay',
    component: () => import('../views/vippay'),
  }
  //分享图片排序
  ,{
    path: '/share_pic_sort',
    name: 'share_pic_sort',
    component: () => import('../views/share_pic_sort'),
  }
  //分享的文本排序
  ,{
    path: '/share_text_sort',
    name: 'share_text_sort',
    component: () => import('../views/share_text_sort'),
  }
  //分享的圖片詳情
  ,{
    path: '/share_day/:id',
    name: 'share_day',
    component: () => import('../views/share_pic_sort/share_day'),
  }
  //分享的文本詳情
  ,{
    path: '/share_text_info/:id',
    name: 'share_text_info',
    component: () => import('../views/share_text_sort/share_text_info'),
  }
  //设置文字大小
  ,{
    path: '/set_font_size',
    name: 'set_font_size',
    component: () => import('../views/my/set_font_size'),
  }
  //今日報數
  ,{
    path: '/count_off_today/:id',
    name: 'count_off_today',
    component: () => import('../views/task/count_off_today'),
  }
  //名单及位置记
  ,{
    path: '/task/placereport/:id',
    name: 'placereport',
    component: () => import('../views/task/placereport'),
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(() => {
  let token = localStorage.getItem('token');
  let user_avatar = localStorage.getItem('user_avatar');
  let user_id = localStorage.getItem('user_id');
  let user_name = localStorage.getItem('user_name');
  let month_goal = localStorage.getItem('month_goal');
  let lang_id = localStorage.getItem('lang_id');
  store.state.user_avatar = user_avatar;
  store.state.user_id = user_id;
  store.state.user_name = user_name;
  store.state.login_verify = token;
  store.state.month_goal = month_goal;
  // lang_id不放到if裏面是因爲 儅你退出賬號時 默認語言為繁體 所以不管是否退出登錄lang_id都有值
  store.state.lang_id = lang_id;
});
// router.beforeEach((to, from, next) => {
//   //to到哪儿 from从哪儿离开 next跳转 为空就是放行 
//   let path = [
//     '/login',
//     '/register',
//     '/my',
//     '/',
//     '/task',
//     '/content'
//   ];

//   let token = localStorage.getItem('token');
//     let user_avatar = localStorage.getItem('user_avatar');
//     let user_id = localStorage.getItem('user_id');
//     let user_name = localStorage.getItem('user_name');
//     let month_goal = localStorage.getItem('month_goal');

//     store.state.user_avatar = user_avatar;
//     store.state.user_id = user_id;
//     store.state.user_name = user_name;

//     store.state.login_verify = token;
//     store.state.month_goal = month_goal;

//   //  if (path.indexOf(to.path) > -1) {
//   //  //如果跳转为登录，就放行 
//   //  localStorage.getItem('token');
//   //   next(); 
//   //  } else {
//   //  //取出localStorage判断
//   //   let token = localStorage.getItem('token');
//   //   let user_avatar = localStorage.getItem('user_avatar');
//   //   let user_id = localStorage.getItem('user_id');
//   //   let user_name = localStorage.getItem('user_name');

//   //   store.state.user_avatar = user_avatar;
//   //   store.state.user_id = user_id;
//   //   store.state.user_name = user_name;
//   //   store.state.login_verify = token;
//   //   // token = localStorage.removeItem('token');
//   //   if (token == null || token === '') { 
//   //     next('/login')
//   //    } else {
//   //      next(); 
//   //    } 
//   // }
// });
  
export default router