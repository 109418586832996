import { createStore } from 'vuex'
import text from "./modules/text"

export default createStore({
    state: {
        // 是否登錄
        login_verify:'',
        // 用户id
        user_id:0,
        // 头像
        user_avatar:'',
        // 用户名
        user_name:'',
        // 上一级课堂id
        parent_course_type_id:0,
        //目標
        month_goal:0,
        add_activation_record_id:0,
        // 是否開通vip
        vip_status:0,
        // 該值用於分享 -> 進入到 分享照片頁 返回問題 默認返回是返回上一頁 可以圖片詳情頁可以查看下一張圖片 這就導致了 返回上一頁時沒法返回到分享頁 而是上一張圖片
        // 再者有兩個地方可以進入到 分享圖片詳情頁 一個是金句海報 一個是最新,理財智慧，保險概念，加油打氣這四塊所以用個值判斷從哪裏進來就返回到哪裏去
        // share_pic_sort 為true時表示從金句海報内進入反之則是最新,理財智慧，保險概念，加油打氣
        share_pic_sort:false,
        // 切換簡繁體時 把底部欄 隱藏
        switchover:false,
        // 切換使用位置 把底部欄 隱藏
        switchoverJaneTraditional:false,
        // 确认框
        confirm:false,
        // 話術練習任務是否完成
        // task_status:false,
        // 語言：1繁體2簡體，默認1
        lang_id:1,
        // 小於0説明 登錄異常了
        login_out:0,
        //  task -> 任務是否完成 0：未完成 1：完成
        is_done:0,
        // 任務標題
        task_title:'',
        // 打卡任務id
        todayUserClockLog_id:0,
        // 用戶創建打卡記錄 - 閱讀筆記 任務ID
        clock_id:0,
        // 用于判断是手机端还是pc端
        port:'',
    },
    modules: {
        text
    },
    mutations:{
        // login_out(){
        //     this.$message.error('身份已过期，请重新登录');
        // }
    },
    actions:{
        // login_out(){
        //     this.$message.error('身份已过期，请重新登录');
        // }
    }
})
