<template>
    <div :class="{ 'mobile-container': !_isMobile }">
        <div id="border" v-if="!_isMobile">
            <div id="fake-mobile">
                <router-view></router-view>
            </div>
        </div>
        <div id="note" v-if="!_isMobile">
            如要達到最佳瀏覽效果，<br />請使用手機瀏覽
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
        <router-view v-else></router-view>
    </div>
</template>
<script>
export default {
    name: "App",
    components: {},
    computed: {
        login_out() {
            return this.$store.state.login_out;
        },
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
    },
    mounted() {
        // if (!this._isMobile || this._isMobile[0] == "iPad") {
        //     this.$store.state.port = "pc";
        // } else {
        this.$store.state.port = "phone";
        // }
    },
    // methods: {
    //     _isMobile() {
    //         let flag = navigator.userAgent.match(
    //             /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //         );
    //         return flag;
    //     },
    // },
    watch: {
        login_out(val) {
            if (val == -2) {
                this.$message.error("419異常");
            } else if (val < 0) {
                this.$message.error("登錄異常，請重新登錄");
            }
        },
        $router(to) {
            this.$nextTick(() => {
                this.$zh_tran("t");
            });
            if (
                to.name == "Timetables" ||
                to.name == "My" ||
                to.name == "message"
            ) {
                this.isShow = true;
            } else {
                this.isShow = false;
            }
        },
    },
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.mobile-container {
    background-color: rgb(196, 155, 108);
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
}
#note {
    font-size: 32px;
    font-weight: 600;
    color: white;
}
#border {
    background-image: url(./assets/iphone_shell.png);
    background-size: 100% 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#fake-mobile {
    background-color: #cba77e;
    width: 320px;
    height: 675px;
    border-radius: 40px;
    transform: translate(0);
    overflow: auto;
    position: relative;
}
::-webkit-scrollbar {
    width: 6px;
    display: none;
}
::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-track {
    background-color: rgb(196, 155, 108);
}
</style>
